.kattay {
    width: 600px;
  }
  
  .input-field {
    border-bottom-width: 1.5px;
  }
  
  .input-field::placeholder {
    border-bottom-width: 2px;
    font-family: FSerStdA-Book;
  }
  
  .Isha-Logo---Black {
  
    width: 45px;
  
    height: 52px;
  
    object-fit: contain;
  margin-left:25px;
  margin-top:-25px;
  margin-bottom:19px;
  }
  .bg-ishaBeige{
  height:80px;
  }
  .Back-to-donations {
    margin-left:25px;
    width: 109px;
  
    height: 17px;
  
    opacity: 0.73;
  
    font-family: FedraSansStd;
  
    font-size: 13px!important;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: 1.25;
  
    letter-spacing: normal;
  
    color: #e4ded4!important;
    ;
  
  }